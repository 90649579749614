import permissions from "@/helpers/Permissions.js";

export const AdminMenuMixin = {
  data: () => ({
    user: null,
    menu: Array,
  }),

  methods: {
    buildMenu(user, showAlerts) {
      this.menu = [];
      if (user) {
        this.user = user;
        this.addHomeMenu();
        this.addAlertsMenu(showAlerts);
        this.addSearchMenu();
        this.addInspectionMenu();
        this.addReportMenu();
        this.addAdminMenu();
        this.addGenerateTokenMenu();
        this.addUpdatesMenu();
        this.addHelpMenu();
      }
      return this.menu;
    },
    addSearchMenu() {
      if (this.hasSearchFunctionality()) {
        const searchMenuItens = [];
        this.addFlightsMenu(searchMenuItens);
        this.addAirlineFlightsMenu(searchMenuItens);
        this.addVUSearchMenu(searchMenuItens);
        this.addCreateQueryMenu(searchMenuItens);
        this.addCrewMenu(searchMenuItens);
        this.addItinerarySearchMenu(searchMenuItens);
        const item = {
          icon: "mdi-account-search",
          "icon-alt": "mdi-account-search-outline",
          text: this.$t("search"),
          model: false,
          children: searchMenuItens,
          pwa: true
        };
        this.menu.push(item);
      }
    },
    hasSearchFunctionality() {
      return (
        this.user.groups.includes(permissions.FLIGHT_LIST) ||
        this.user.groups.includes(permissions.AIRLINE_FLIGHT_LIST) ||
        this.user.groups.includes(permissions.SEARCH_PASSENGERS) ||
        this.user.groups.includes(permissions.SEARCH_CREW)
      );
    },
    addFlightsMenu(searchMenuItens) {
      if (this.user.groups.includes(permissions.FLIGHT_LIST)) {
        const item = {
          icon: "mdi-airplane",
          "icon-alt": "mdi-airplane",
          text: this.$t("flight"),
          link: "flights",
          hint: this.$t("flightsHint"),
          pwa: true
        };

        searchMenuItens.push(item);
      }
    },
    addAirlineFlightsMenu(searchMenuItens) {
      if (this.user.groups.includes(permissions.AIRLINE_FLIGHT_LIST)) {
        const item = {
          icon: "mdi-airplane",
          "icon-alt": "mdi-airplane",
          text: this.$t("airlineFlight"),
          link: "airline-flights",
          hint: this.$t("airlineFlightsHint"),
        };
        searchMenuItens.push(item);
      }
    },
    addVUSearchMenu(searchMenuItens) {
      if (this.user.groups.includes(permissions.SEARCH_PASSENGERS)) {
        const item = {
          icon: "mdi-walk",
          text: this.$t("uniqueTraveler"),
          link: "search-vus",
          hint: this.$t("uniqueTravelerHint"),
          pwa: true
        };
        searchMenuItens.push(item);
      }
    },
    addCreateQueryMenu(searchMenuItens) {
      if (this.user.groups.includes(permissions.SEARCH_PASSENGERS)) {
        const item = {
          icon: "mdi-star",
          text: this.$t("customQuery"),
          link: "list-custom-query",
        };
        searchMenuItens.push(item);
      }
    },
    addCrewMenu(searchMenuItens) {
      if (this.user.groups.includes(permissions.SEARCH_CREW)) {
        const item = {
          icon: "mdi-account-tie-hat",
          text: this.$t("crew"),
          link: "crews",
        };
        searchMenuItens.push(item);
      }
    },
    addItinerarySearchMenu(searchMenuItens) {
      if (this.user.groups.includes(permissions.ITINERARY_SEARCH)) {
        const item = {
          icon: "mdi-airplane-settings",
          text: this.$t("itinerary"),
          link: "itinerary-search",
          hint: this.$t("itinerarySearchHint"),
        };
        searchMenuItens.push(item);
      }
    },
    addAdminMenu() {
      if (this.hasAdminFunctionality()) {
        const adminMenuItens = [];
        this.addListRoleMenu(adminMenuItens);
        this.addListUserMenu(adminMenuItens);
        this.addImportUserMenu(adminMenuItens);
        this.addListOrganizationsMenu(adminMenuItens);
        this.addListAirportsMenu(adminMenuItens);
        this.addListApiAuditMenu(adminMenuItens);
        this.addListApiPnrPanelMenu(adminMenuItens);
        this.addListUserAirportMenu(adminMenuItens);
        this.addListOrganizationAirportMenu(adminMenuItens);
        this.addListCountryMenu(adminMenuItens);
        this.addListAirlinesMenu(adminMenuItens);
        this.addCustomerMenu(adminMenuItens);
        const item = {
          icon: "mdi-cog",
          "icon-alt": "mdi-cog",
          text: this.$t("administration"),
          model: false,
          children: adminMenuItens,
        };
        this.menu.push(item);
      }
    },
    hasAdminFunctionality() {
      return (
        this.user.groups.includes(permissions.ROLE_MANAGE) ||
        this.user.groups.includes(permissions.USER_MANAGE) ||
        this.user.groups.includes(permissions.API_AUDIT_SEARCH) ||
        this.user.groups.includes(permissions.INTEGRATOR_MANAGE) ||
        this.user.groups.includes(permissions.ORGANIZATION_MANAGE) ||
        this.user.groups.includes(permissions.AIRPORT_MANAGE) ||
        this.user.groups.includes(permissions.USER_AIRPORT) ||
        this.user.groups.includes(permissions.ORGANIZATION_AIRPORT) ||
        this.user.groups.includes(permissions.COUNTRY_MANAGE) ||
        this.user.groups.includes(permissions.AIRLINE_MANAGE)
      );
    },
    addListRoleMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.ROLE_MANAGE)) {
        const item = {
          icon: "mdi-menu",
          text: this.$t("role"),
          link: "list-role",
          hint: this.$t("roleHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListUserMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.USER_MANAGE)) {
        const item = {
          icon: "mdi-account-details-outline",
          text: this.$t("user"),
          link: "list-user",
          hint: this.$t("userHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addImportUserMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.USER_MANAGE)) {
        const item = {
          icon: "mdi-account-details-outline",
          text: this.$t("importUser"),
          link: "import-user",
          hint: this.$t("userHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListOrganizationsMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.ORGANIZATION_MANAGE)) {
        const item = {
          icon: "mdi-domain",
          text: this.$t("organizations"),
          link: "list-organization",
          hint: this.$t("organizationsHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListAirportsMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.AIRPORT_MANAGE)) {
        const item = {
          icon: "mdi-airport",
          text: this.$t("airports"),
          link: "list-airport",
          hint: this.$t("airportsHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListApiAuditMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.API_AUDIT_SEARCH)) {
        const item = {
          icon: "mdi-database-search",
          text: this.$t("apiAudit"),
          link: "list-api-audit",
          hint: this.$t("apiAuditHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListApiPnrPanelMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.API_PNR_PANEL)) {
        const item = {
          icon: "mdi-file-send-outline",
          text: this.$t("apiPnrPanel"),
          link: "list-api-pnr",
          hint: this.$t("apiPnrPanelHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListUserAirportMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.USER_AIRPORT)) {
        const item = {
          icon: "mdi-airport",
          text: this.$t("user_airport"),
          link: "list-user-airport",
          hint: this.$t("user_airportHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListOrganizationAirportMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.ORGANIZATION_AIRPORT)) {
        const item = {
          icon: "mdi-airport",
          text: this.$t("organization_airport"),
          link: "list-organization-airport",
          hint: this.$t("organization_airportHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListCountryMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.COUNTRY_MANAGE)) {
        const item = {
          icon: "mdi-earth",
          text: this.$t("countries"),
          link: "list-country",
          hint: this.$t("countryHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addListAirlinesMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.AIRLINE_MANAGE)) {
        const item = {
          icon: "mdi-shield-airplane",
          text: this.$t("airlines"),
          link: "list-airline",
          hint: this.$t("airlinesHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addGenerateTokenMenu() {
      if (this.user.groups.includes(permissions.INTEGRATOR)) {
        const item = {
          icon: "mdi-shield-key",
          text: this.$t("generateToken"),
          link: "generate-token",
          hint: this.$t("generateTokenHint"),
        };
        this.menu.push(item);
      }
    },
    addRestrictionListMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.RESTRICTION_LIST)) {
        const item = {
          icon: "mdi-account-alert",
          text: this.$t("restrictionList"),
          link: "restriction-list",
          hint: this.$t("restrictionListHint"),
        };
        inspectionMenuItens.push(item);
      }
    },

    addCategoryMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.CATEGORY_MANAGE)) {
        const item = {
          icon: "mdi-format-list-checks",
          text: this.$t("category"),
          link: "category-list",
          hint: this.$t("categoryHint"),
        };
        inspectionMenuItens.push(item);
      }
    },
    addWorkTeamsMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.WORK_TEAM_MANAGE)) {
        const item = {
          icon: "mdi-account-group",
          text: this.$t("workTeams"),
          link: "work-teams-list",
          hint: this.$t("workTeamsHint"),
        };
        inspectionMenuItens.push(item);
      }
    },
    addIconActionMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.ICON_ACTION_MANAGE)) {
        const item = {
          icon: "mdi-image-area",
          text: this.$t("iconAction"),
          link: "icon-action-list",
          hint: this.$t("iconActionHint"),
        };
        inspectionMenuItens.push(item);
      }
    },
    addInspectionsAndOccurenciesMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.INSPECTIONS_AND_OCCURENCIES)) {
        const item = {
          icon: "mdi-airplane-alert",
          text: this.$t("searchInspectionsAndOccurencies"),
          link: "inspections-and-occurencies",
          hint: this.$t("searchInspectionsAndOccurenciesHint"),
        };
        inspectionMenuItens.push(item);
      }
    },
    addRiskRuleMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.RISK_MANAGE)) {
        const item = {
          icon: "mdi-alert-outline",
          text: this.$t("riskRules"),
          link: "list-rule",
          hint: this.$t("riskRulesHint"),
        };
        inspectionMenuItens.push(item);
      }
    },
    addInternationalMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.INTERNATIONAL)) {
        const item = {
          icon: "mdi-alpha-i-circle",
          text: this.$t("international"),
          link: "international",
          hint: this.$t("internationalHint"),
        };
        inspectionMenuItens.push(item);
      }
    },
    addDomesticMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.DOMESTIC)) {
        const item = {
          icon: "mdi-alpha-d-circle",
          text: this.$t("domestic"),
          link: "domestic",
          hint: this.$t("domesticHint"),
        };
        inspectionMenuItens.push(item);
      }
    },
    addExportApiPnrMenu(inspectionMenuItens) {
      if (this.user.groups.includes(permissions.EXPORT_APIPNR)) {
        const item = {
          icon: "mdi-download",
          text: this.$t("exportApipnr"),
          link: "export-apipnr",
        };
        inspectionMenuItens.push(item);
      }
    },
    hasInspectionFunctionality() {
      return (
        this.user.groups.includes(permissions.RISK_MANAGE) ||
        this.user.groups.includes(permissions.DOMESTIC) ||
        this.user.groups.includes(permissions.INTERNATIONAL) ||
        this.user.groups.includes(permissions.RESTRICTION_LIST) ||
        this.user.groups.includes(permissions.CATEGORY_MANAGE) ||
        this.user.groups.includes(permissions.ICON_ACTION_MANAGE)
      );
    },
    addInspectionMenu() {
      if (this.hasInspectionFunctionality()) {
        const inspectionMenuItens = [];
        this.addRiskRuleMenu(inspectionMenuItens);
        this.addDomesticMenu(inspectionMenuItens);
        this.addInternationalMenu(inspectionMenuItens);
        this.addRestrictionListMenu(inspectionMenuItens);
        this.addCategoryMenu(inspectionMenuItens);
        this.addWorkTeamsMenu(inspectionMenuItens);
        this.addIconActionMenu(inspectionMenuItens);
        this.addInspectionsAndOccurenciesMenu(inspectionMenuItens);
        const item = {
          icon: "mdi-briefcase-search",
          "icon-alt": "mdi-briefcase-search-outline",
          text: this.$t("inspection"),
          model: false,
          children: inspectionMenuItens,
        };
        this.menu.push(item);
      }
    },
    addInspectionReportMenu(reportMenuItens) {
      if (this.user.groups.includes(permissions.INSPECTION_REPORT)) {
        const item = {
          icon: "mdi-clipboard-search",
          text: this.$t("inspectionReport"),
          link: "inspection-report",
        };
        reportMenuItens.push(item);
      }
      if(this.user.groups.includes(permissions.INSPECTION_SYNTHETIC)){
        const item2 = {
          icon: "mdi-clipboard-search",
          text: this.$t("inspectionSynthetic"),
          link: "inspection-sintetic",
        };
        reportMenuItens.push(item2);
      }
      if(this.user.groups.includes(permissions.INSPECTION_ANALITIC)){
        const item2 = {
          icon: "mdi-clipboard-search",
          text: this.$t("inspectionAnalitic"),
          link: "inspection-analitic",
        };
        reportMenuItens.push(item2);
      }
      
    },
    addIndicatorsReportMenu(reportMenuItens) {
      if (this.user.groups.includes(permissions.INDICATORS_REPORT)) {
        const item = {
          icon: "mdi-chart-bar",
          text: this.$t("indicatorsReport"),
          link: "indicators-report",
        };
        reportMenuItens.push(item);
      }
      if (this.user.groups.includes(permissions.INDICATORS_EMBARQUESEGURO_REPORT)) {
        const item = {
          icon: "mdi-chart-bar",
          text: this.$t("indicatorsEmbarqueReport"),
          link: "indicators-embarque-report",
        };
        reportMenuItens.push(item);
      }
    },
    addGraphicIndicatorsApiReportMenu(reportMenuItens) {
      if (
        this.user.groups.includes(permissions.GRAPHIC_INDICATORS_API_REPORT)
      ) {
        const item = {
          icon: "mdi-chart-bar",
          text: this.$t("graphicIndicatorsApiReport"),
          link: "graphic-indicators-api-report",
        };
        reportMenuItens.push(item);
      }
    },
    addGraphicIndicatorsReportMenu(reportMenuItens) {
      if (this.user.groups.includes(permissions.GRAPHIC_INDICATORS_REPORT)) {
        const item = {
          icon: "mdi-chart-bar",
          text: this.$t("graphicIndicatorsEmbarqueReport"),
          link: "graphic-indicators-embarque-report",
        };
        reportMenuItens.push(item);
      }
    },
    addSisbraipIndicatorsReportMenu(reportMenuItens) {
      if (this.user.groups.includes(permissions.SISBRAIP_INDICATORS_REPORT)) {
        const item = {
          icon: "mdi-chart-bar",
          text: this.$t("sisbraipIndicators"),
          link: "sisbraip-indicators",
        };
        reportMenuItens.push(item);
      }
    },
    addSisbraipTotalizersReportMenu(reportMenuItens) {
      if (this.user.groups.includes(permissions.INDICATORS_TOTALIZATORS)) {
        const item = {
          icon: "mdi-chart-bar",
          text: this.$t("panelTotalizers"),
          link: "totalizers-report",
        };
        reportMenuItens.push(item);
      }
    },
    addApiRequestReportMenu(reportMenuItens) {
      if (this.user.groups.includes(permissions.API_REQUEST_REPORT)) {
        const item = {
          icon: "mdi-database-search",
          text: this.$t("apiRequestReport"),
          link: "api-request-report",
        };
        reportMenuItens.push(item);
      }
    },
    hasReportFunctionality() {
      return (
        this.user.groups.includes(permissions.INSPECTION_REPORT) ||
        this.user.groups.includes(permissions.INDICATORS_REPORT) ||
        this.user.groups.includes(permissions.SISBRAIP_INDICATORS_REPORT) ||
        this.user.groups.includes(permissions.GRAPHIC_INDICATORS_REPORT) ||
        this.user.groups.includes(permissions.GRAPHIC_INDICATORS_API_REPORT) ||
        this.user.groups.includes(permissions.API_REQUEST_REPORT) ||
        this.user.groups.includes(permissions.INSPECTION_SYNTHETIC) ||
        this.user.groups.includes(permissions.INSPECTION_ANALITIC) ||
        this.user.groups.includes(permissions.INDICATORS_EMBARQUESEGURO_REPORT)
      );
    },
    addReportMenu() {
      if (this.hasReportFunctionality()) {
        const reportMenuItens = [];
        this.addInspectionReportMenu(reportMenuItens);
        this.addIndicatorsReportMenu(reportMenuItens);
        this.addSisbraipIndicatorsReportMenu(reportMenuItens);
        this.addGraphicIndicatorsReportMenu(reportMenuItens);
        this.addGraphicIndicatorsApiReportMenu(reportMenuItens);
        this.addSisbraipTotalizersReportMenu(reportMenuItens);
        this.addApiRequestReportMenu(reportMenuItens);
        const item = {
          icon: "mdi-file-document-multiple",
          "icon-alt": "mdi-file-document-multiple-outline",
          text: this.$t("reports"),
          model: false,
          children: reportMenuItens,
        };
        this.menu.push(item);
      }
    },
    addCustomerMenu(adminMenuItens) {
      if (this.user.groups.includes(permissions.BILLING_MANAGE)) {
        const item = {
          icon: "mdi-cash-multiple",
          text: this.$t("billing"),
          link: "list-billing",
          hint: this.$t("billingHint"),
        };
        adminMenuItens.push(item);
      }
    },
    addUpdatesMenu() {
      if (
        this.user.groups.includes(permissions.UPDATES_READ) ||
        this.user.groups.includes(permissions.UPDATES_EDIT)
      ) {
        let query;
        if (this.user.groups.includes(permissions.UPDATES_EDIT)) {
          query = { enableEdit: "true" };
        }
        const item = {
          icon: "mdi-bullhorn",
          "icon-alt": "mdi-bullhorn-outline",
          text: this.$t("updates"),
          link: "list-updates",
          hint: this.$t("updatesHint"),
          query: query,
        };
        this.menu.push(item);
      }
    },
    addHelpMenu() {
      if (
        this.user.groups.includes(permissions.HELP_READ) ||
        this.user.groups.includes(permissions.HELP_EDIT)
      ) {
        let query;
        if (this.user.groups.includes(permissions.HELP_EDIT)) {
          query = { enableEdit: "true" };
        }
        const item = {
          icon: "mdi-help-circle",
          text: this.$t("help"),
          link: "list-help",
          hint: this.$t("helpHint"),
          query: query,
        };
        this.menu.push(item);
      }
    },
    addAlertsMenu(showAlerts) {
      if (showAlerts) {
        const item = {
          icon: "mdi-bell-alert",
          text: this.$t("alerts"),
          link: "alerts",
          hint: this.$t("alertsHint"),
          pwa: true
        };
        this.menu.push(item);
      }
    },
    addHomeMenu() {
      const item = {
        icon: "mdi-home",
        text: this.$t("home"),
        link: "home",
        pwa: true
      };
      this.menu.push(item);
    },
  },
};
