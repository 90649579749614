export default [
  {
    path: "inspectionReport",
    name: "inspection-report",
    component: () =>
      import("@/views/reports/inspectionReport/InspectionReport.vue"),
  },
  {
    path: "inspectionSintetic",
    name: "inspection-sintetic",
    component: () =>
      import("@/views/reports/inspectionReport/InspectionSintetic.vue"),
  },
  {
    path: "inspectionAnalitic",
    name: "inspection-analitic",
    component: () =>
      import("@/views/reports/inspectionReport/InspectionAnalitic.vue"),
  },
  {
    path: "indicatorsReport",
    name: "indicators-report",
    component: () =>
      import("@/views/reports/indicatorsReport/IndicatorsReport.vue"),
  },
  {
    path: "indicatorsReport/excludedFlights",
    name: "excluded-flights",
    component: () =>
      import("@/views/reports/indicatorsReport/ExcludedFlights.vue"),
  },
  {
    path: "sisbraipIndicators",
    name: "sisbraip-indicators",
    component: () =>
      import("@/views/reports/sisbraipIndicators/SisbraipIndicators.vue"),
  },
  {
    path: "apiRequestReport/ApiRequestReport",
    name: "api-request-report",
    component: () =>
      import("@/views/reports/apiRequestReport/ApiRequestReport.vue"),
  },
  {
    path: "indicatorsEmbarqueReport",
    name: "indicators-embarque-report",
    component: () =>
      import("@/views/reports/indicatorsReport/IndicatorsEmbarqueReport.vue"),
  },
  {
    path: "graphicIndicatorsEmbarqueReport",
    name: "graphic-indicators-embarque-report",
    component: () =>
      import(
        "@/views/reports/indicatorsReport/GraphicIndicatorsEmbarqueReport.vue"
      ),
  },
  {
    path: "graphicIndicatorsApiReport",
    name: "graphic-indicators-api-report",
    component: () =>
      import(
        "@/views/reports/graphicIndicatorsApiReport/GraphicIndicatorsApiReport.vue"
      ),
  },
  {
    path: "totalizersReport",
    name: "totalizers-report",
    component: () =>
      import(
        "@/views/reports/totalizersReport/TotalizersReport.vue"
      ),
  },
];
