const permissions = {
  FLIGHT_LIST: "FLIGHT_LIST",
  AIRLINE_FLIGHT_LIST: "AIRLINE_FLIGHT_LIST",
  SEARCH_PASSENGERS: "SEARCH_PASSENGERS",
  ROLE_MANAGE: "ROLE_MANAGE",
  USER_MANAGE: "USER_MANAGE",
  API_AUDIT_SEARCH: "API_AUDIT_SEARCH",
  INTEGRATOR: "INTEGRATOR",
  ORGANIZATION_MANAGE: "ORGANIZATION_MANAGE",
  AIRPORT_MANAGE: "AIRPORT_MANAGE",
  RISK_MANAGE: "RISK_MANAGE",
  INTERNATIONAL: "INTERNATIONAL",
  DOMESTIC: "DOMESTIC",
  USER_AIRPORT: "USER_AIRPORT",
  ORGANIZATION_AIRPORT: "ORGANIZATION_AIRPORT",
  RESTRICTION_LIST: "RESTRICTION_LIST",
  CATEGORY_MANAGE: "CATEGORY_MANAGE",
  EXPORT_APIPNR: "EXPORT_APIPNR",
  INSPECTION_REPORT: "INSPECTION_REPORT",
  INSPECTION_SYNTHETIC: "INSPECTION_SYNTHETIC",
  INSPECTION_ANALITIC: "INSPECTION_ANALITIC",
  INDICATORS_REPORT: "INDICATORS_REPORT",
  INDICATORS_TOTALIZATORS: "INDICATORS_TOTALIZATORS",
  API_REQUEST_REPORT: "API_REQUEST_REPORT",
  COUNTRY_MANAGE: "COUNTRY_MANAGE",
  SEARCH_CREW: "SEARCH_CREW",
  AIRLINE_MANAGE: "AIRLINE_MANAGE",
  API_PNR_PANEL: "API_PNR_PANEL",
  BILLING_MANAGE: "BILLING_MANAGE",
  WORK_TEAM_MANAGE: "WORK_TEAM_MANAGE",
  SISBRAIP_INDICATORS_REPORT: "SISBRAIP_INDICATORS_REPORT",
  GRAPHIC_INDICATORS_REPORT: "GRAPHIC_INDICATORS_REPORT",
  GRAPHIC_INDICATORS_API_REPORT: "GRAPHIC_INDICATORS_API_REPORT",
  UPDATES_EDIT: "UPDATES_EDIT",
  UPDATES_READ: "UPDATES_READ",
  ICON_ACTION_MANAGE: "ICON_ACTION_MANAGE",
  TISBA: "TISBA",
  DELETE_OCURRENCE: "DELETE_OCURRENCE",
  INSPECTIONS_AND_OCCURENCIES: "INSPECTIONS_AND_OCCURENCIES",
  HELP_EDIT: "HELP_EDIT",
  HELP_READ: "HELP_READ",
  INDICATORS_EMBARQUESEGURO_REPORT: "INDICATORS_EMBARQUESEGURO_REPORT",
  VISUALIZE_RAW_PNR: "VISUALIZE_RAW_PNR",  
  TCSV: "TCSV",
  ITINERARY_SEARCH: "ITINERARY_SEARCH"
};

export default permissions;
